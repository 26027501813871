import type {
  ExcalidrawElement,
  FileId,
} from "../../packages/excalidraw/element/types";
import { getSceneVersion } from "../../packages/excalidraw/element";
import type Portal from "../collab/Portal";
import { restoreElements } from "../../packages/excalidraw/data/restore";
import type {
  AppState,
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
} from "../../packages/excalidraw/types";
import { decompressData } from "../../packages/excalidraw/data/encode";
import {
  encryptData,
  decryptData,
  IV_LENGTH_BYTES,
} from "../../packages/excalidraw/data/encryption";
import { MIME_TYPES } from "../../packages/excalidraw/constants";
import type { SyncableExcalidrawElement } from ".";
import { getSyncableElements } from ".";
import type { Socket } from "socket.io-client";

const HTTP_BACKEND_URL = import.meta.env.VITE_APP_HTTP_STORAGE_BACKEND_URL;

interface BackendStoredScene {
  sceneVersion: number;
  iv: Uint8Array;
  ciphertext: Uint8Array;
}

const encryptElements = async (
  key: string,
  elements: readonly ExcalidrawElement[],
): Promise<{ ciphertext: ArrayBuffer; iv: Uint8Array }> => {
  const json = JSON.stringify(elements);
  const encoded = new TextEncoder().encode(json);
  const { encryptedBuffer, iv } = await encryptData(key, encoded);

  return { ciphertext: encryptedBuffer, iv };
};

const decryptElements = async (
  data: BackendStoredScene,
  roomKey: string,
): Promise<readonly ExcalidrawElement[]> => {
  const ciphertext = data.ciphertext;
  const iv = data.iv;

  const decrypted = await decryptData(iv, ciphertext, roomKey);
  const decodedData = new TextDecoder("utf-8").decode(
    new Uint8Array(decrypted),
  );
  return JSON.parse(decodedData);
};

class SceneVersionCache {
  private static cache = new WeakMap<Socket, number>();
  static get = (socket: Socket) => {
    return SceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: Socket,
    elements: readonly SyncableExcalidrawElement[],
  ) => {
    SceneVersionCache.cache.set(socket, getSceneVersion(elements));
  };
}

export const isSavedToBackend = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);

    return SceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

export const saveFilesToBackend = async ({
  prefix,
  files,
}: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  const erroredFiles: FileId[] = [];
  const savedFiles: FileId[] = [];

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        const payloadBlob = new Blob([buffer]);
        const payload = await new Response(payloadBlob).arrayBuffer();

        const response = await fetch(`${HTTP_BACKEND_URL}/files/${id}`, {
          method: "PUT",
          body: payload,
        });

        if (!response.ok) {
          throw new Error("Failed to save file");
        }

        savedFiles.push(id);
      } catch (error) {
        erroredFiles.push(id);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

const createBackendSceneDocument = async (
  elements: readonly SyncableExcalidrawElement[],
  roomKey: string,
): Promise<BackendStoredScene> => {
  const sceneVersion = getSceneVersion(elements);
  const { ciphertext, iv } = await encryptElements(roomKey, elements);
  return {
    sceneVersion,
    ciphertext: new Uint8Array(ciphertext),
    iv,
  };
};

export const saveToBackend = async (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
) => {
  const { roomId, roomKey, socket } = portal;
  if (
    // bail if no room exists as there's nothing we can do at this point
    !roomId ||
    !roomKey ||
    !socket ||
    isSavedToBackend(portal, elements)
  ) {
    return null;
  }

  const storedScene = await createBackendSceneDocument(elements, roomKey);

  try {
    const numberBuffer = new ArrayBuffer(4);
    const numberView = new DataView(numberBuffer);
    numberView.setUint32(0, storedScene.sceneVersion, false);
    const sceneVersionBuffer = numberView.buffer;
    const payloadBlob = await new Response(
      new Blob([
        sceneVersionBuffer,
        storedScene.iv.buffer,
        storedScene.ciphertext,
      ]),
    ).arrayBuffer();

    const response = await fetch(`${HTTP_BACKEND_URL}/rooms/${roomId}`, {
      method: "PUT",
      body: payloadBlob,
    });

    if (!response.ok) {
      throw new Error("Failed to save to backend");
    }

    const storedElements = getSyncableElements(
      restoreElements(await decryptElements(storedScene, roomKey), null),
    );

    SceneVersionCache.set(socket, storedElements);

    return storedElements;
  } catch (error) {
    console.error("Error saving to backend:", error);
    return null;
  }
};

export const loadFromBackend = async (
  roomId: string,
  roomKey: string,
  socket: Socket | null,
): Promise<readonly SyncableExcalidrawElement[] | null> => {
  try {
    const response = await fetch(`${HTTP_BACKEND_URL}/rooms/${roomId}`);
    if (!response.ok) {
      throw new Error("Failed to load from backend");
    }
    const buffer = await response.arrayBuffer();
    const scene = await getElementsFromBuffer(buffer);

    const elements = getSyncableElements(
      restoreElements(await decryptElements(scene, roomKey), null),
    );

    if (socket) {
      SceneVersionCache.set(socket, elements);
    }

    return elements;
  } catch (error) {
    console.error("Error loading from backend:", error);
    return null;
  }
};

export const loadFilesFromBackend = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const response = await fetch(`${HTTP_BACKEND_URL}/files/${id}`);
        if (!response.ok) {
          throw new Error("Failed to load file");
        }

        const arrayBuffer = await response.arrayBuffer();
        const { data, metadata } = await decompressData<BinaryFileMetadata>(
          new Uint8Array(arrayBuffer),
          { decryptionKey },
        );

        const dataURL = new TextDecoder().decode(data) as DataURL;

        loadedFiles.push({
          mimeType: metadata.mimeType || MIME_TYPES.binary,
          id,
          dataURL,
          created: metadata?.created || Date.now(),
          lastRetrieved: metadata?.created || Date.now(),
        });
      } catch (error) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};

const parseSceneVersionFromRequest = (buffer: ArrayBuffer) => {
  const view = new DataView(buffer);
  return view.getUint32(0, false);
};

const SCENE_VERSION_LENGTH_BYTES = 4;

const getElementsFromBuffer = async (
  buffer: ArrayBuffer,
): Promise<BackendStoredScene> => {
  // Buffer should contain both the IV (fixed length) and encrypted data
  const sceneVersion = parseSceneVersionFromRequest(buffer);
  const iv = new Uint8Array(
    buffer.slice(
      SCENE_VERSION_LENGTH_BYTES,
      IV_LENGTH_BYTES + SCENE_VERSION_LENGTH_BYTES,
    ),
  );
  const encrypted = buffer.slice(
    IV_LENGTH_BYTES + SCENE_VERSION_LENGTH_BYTES,
    buffer.byteLength,
  );

  return {
    sceneVersion,
    iv,
    ciphertext: new Uint8Array(encrypted),
  };
};
